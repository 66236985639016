@import './vars.scss';

html,
body {
  height: 100%;
  margin: 0;
  font-size: 1.4em;
  font-family: Montserrat, sans-serif;
  color: $dark;
  --tooltipFontSize: 32px;
  --tooltipPadding: 10px;
}

input {
  outline: none;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-by-side {
  display: flex;
  gap: 10px;
  width: 90%;
  flex-wrap: wrap;
  align-items: center;
  > * {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

button {
  border: unset;
  background: unset;
  cursor: unset;
  outline: unset;
  color: unset;
}

.action-button-group {
  border-radius: $icon-button-border-radius;
  overflow: hidden;

  .button {
    color: #fff;
    border-radius: unset;
    background-color: $dark;
    padding: 25px;
    font-size: 64px;
    &:not(:disabled) {
      &:hover,
      &:active {
        background-color: $lighDark;
      }
    }
  }
}

.button {
  &.icon {
    line-height: 0;
  }
  font-family: Montserrat, sans-serif;
  font-size: 35px;
  margin: 0;
  color: $dark;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 5px;
  box-shadow: $shadow;
  transition: all 0.25s;
  padding: 18px;
  &.invalid.ng-touched,
  &.ng-invalid.ng-touched {
    box-shadow: $shadow-red;
  }
  &:not(:disabled) {
    cursor: pointer;
    &:hover,
    &:active {
      background-color: $dark;
      color: #fff;
      &.action {
        background-color: $lighDark;
      }
    }
    &:focus {
      border: 2px solid $dark;
    }
    &:active {
      transition: all 0s;
      background-color: $lighDark;
    }
  }
  &:disabled {
    box-shadow: $shadow inset;
    color: gray;
  }
}

$tooltipBorderSize: 5px;
$tooltipBorder: $tooltipBorderSize white solid;

.tooltip,
.tooltip::before,
.tooltip::after {
  position: absolute;
  background-color: $dark;
  font-size: var(--tooltipFontSize);
  padding: var(--tooltipPadding);
  border-top: $tooltipBorder;
  border-bottom: $tooltipBorder;
}

.tooltip {
  background-color: $dark;
  color: white;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: opacity 250ms;
  z-index: 3;
  &.visible {
    opacity: 1;
  }
  &::before,
  &::after {
    content: '!';
    color: $dark;
    top: -$tooltipBorderSize;
    transform: skewX(-12deg);
    z-index: -1;
  }
  &::before {
    left: -8px;
    border-left: $tooltipBorder;
  }
  &::after {
    right: -8px;
    border-right: $tooltipBorder;
  }
}

.dialog-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog-container {
    position: relative;
    width: max(50vw, 500px);
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: $shadow;
    padding: 25px;
    &::after {
      // make space for actions
      content: '';
      height: 80px;
      width: 1px;
    }
    .close-button {
      display: flex;
      bottom: 20px;
      right: 20px;
    }
  }
}
